var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-layout-sider",
    {
      style: {
        overflow: "auto",
        left: 0,
        top: 0,
        bottom: 0,
        background: "#fff",
        height: "100%",
      },
      attrs: { width: "350", collapsible: "" },
      model: {
        value: _vm.collapsed,
        callback: function ($$v) {
          _vm.collapsed = $$v
        },
        expression: "collapsed",
      },
    },
    [
      _c(
        "a-menu",
        {
          style: { height: "100%", flex: "none" },
          attrs: {
            mode: "inline",
            "selected-keys": _vm.selectedKeys,
            "open-keys": _vm.openKeys,
            "inline-collapsed": _vm.collapsed,
          },
          on: { openChange: _vm.onOpenChange },
        },
        [
          _c(
            "a-menu-item",
            { key: "home" },
            [
              _c(
                "router-link",
                { attrs: { to: "/" } },
                [
                  _c("a-icon", { attrs: { type: "home" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(_vm.$t("global.menu.home")),
                      },
                    },
                    [_vm._v("Home")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "admin",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "setting" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.admin.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("Administration")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1020464137
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "a-menu-item",
                    { key: "user-management" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/user-management" } },
                        [
                          _c("a-icon", { attrs: { type: "team" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.userManagement")
                                ),
                              },
                            },
                            [_vm._v("User management")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-menu-item",
                    { key: "functional" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/functional" } },
                        [
                          _c("a-icon", { attrs: { type: "apartment" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.functional")
                                ),
                              },
                            },
                            [_vm._v("Functional")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-menu-item",
                    { key: "authority-functional" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/authority-functional" } },
                        [
                          _c("a-icon", { attrs: { type: "usergroup-add" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "global.menu.admin.authorityFunctional"
                                  )
                                ),
                              },
                            },
                            [_vm._v("Authority functional")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-menu-item",
                    { key: "feedback-category" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/feedback-category" } },
                        [
                          _c("a-icon", { attrs: { type: "read" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.feedbackCategory")
                                ),
                              },
                            },
                            [_vm._v("Feedback Category")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0002") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "school",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "bank" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.school.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("School configuration")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3164851400
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000201")
                    ? _c(
                        "a-menu-item",
                        { key: "school-year" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/school-year" } },
                            [
                              _c("a-icon", { attrs: { type: "calendar" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.school.schoolYear")
                                    ),
                                  },
                                },
                                [_vm._v("School year")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000202")
                    ? _c(
                        "a-menu-item",
                        { key: "semester" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/semester" } },
                            [
                              _c("a-icon", { attrs: { type: "calendar" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.school.semester")
                                    ),
                                  },
                                },
                                [_vm._v("Semester")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000203")
                    ? _c(
                        "a-menu-item",
                        { key: "school-level" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/school-level" } },
                            [
                              _c("a-icon", { attrs: { type: "branches" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.school.schoolLevel")
                                    ),
                                  },
                                },
                                [_vm._v("School level")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000204")
                    ? _c(
                        "a-menu-item",
                        { key: "course" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/course" } },
                            [
                              _c("a-icon", { attrs: { type: "profile" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.school.course")
                                    ),
                                  },
                                },
                                [_vm._v("Course")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000205")
                    ? _c(
                        "a-menu-item",
                        { key: "class-room" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/class-room" } },
                            [
                              _c("a-icon", { attrs: { type: "apartment" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.school.classRoom")
                                    ),
                                  },
                                },
                                [_vm._v("Class room")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000206")
                    ? _c(
                        "a-menu-item",
                        { key: "contact-hour" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/contact-hour" } },
                            [
                              _c("a-icon", { attrs: { type: "calculator" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.school.contactHour")
                                    ),
                                  },
                                },
                                [_vm._v("Contact hour")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000207")
                    ? _c(
                        "a-menu-item",
                        { key: "subject" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/subject" } },
                            [
                              _c("a-icon", { attrs: { type: "read" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.school.subject")
                                    ),
                                  },
                                },
                                [_vm._v("Subject")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000208")
                    ? _c(
                        "a-menu-item",
                        { key: "holiday-schedule" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/holiday-schedule" } },
                            [
                              _c("a-icon", { attrs: { type: "calendar" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.school.holidaySchedule"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Holiday schedule")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0003") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "score",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", {
                                  attrs: { type: "account-book" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.score.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("Score")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2047083739
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000301")
                    ? _c(
                        "a-menu-item",
                        { key: "scoring-scale" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/scoring-scale" } },
                            [
                              _c("a-icon", { attrs: { type: "container" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.score.scoringScale")
                                    ),
                                  },
                                },
                                [_vm._v("Scoring scale")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000302")
                    ? _c(
                        "a-menu-item",
                        { key: "scoring-type" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/scoring-type" } },
                            [
                              _c("a-icon", { attrs: { type: "container" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.score.scoringType")
                                    ),
                                  },
                                },
                                [_vm._v("Scoring type")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000303")
                    ? _c(
                        "a-menu-item",
                        { key: "score-group" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/score-group" } },
                            [
                              _c("a-icon", { attrs: { type: "container" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.score.scoreGroup")
                                    ),
                                  },
                                },
                                [_vm._v("Score group")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000304")
                    ? _c(
                        "a-menu-item",
                        { key: "subject-score" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/subject-score" } },
                            [
                              _c("a-icon", { attrs: { type: "container" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.score.subjectScore")
                                    ),
                                  },
                                },
                                [_vm._v("Subject score")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000307")
                    ? _c(
                        "a-menu-item",
                        { key: "education-department-score" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/education-department-score" } },
                            [
                              _c("a-icon", { attrs: { type: "container" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.score.educationDepartmentScore"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Education department score")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000305")
                    ? _c(
                        "a-menu-item",
                        { key: "import-score" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/import-score" } },
                            [
                              _c("a-icon", { attrs: { type: "container" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.score.importScore")
                                    ),
                                  },
                                },
                                [_vm._v("Import score")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000306")
                    ? _c(
                        "a-menu-item",
                        { key: "score-lock" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/score-lock" } },
                            [
                              _c("a-icon", { attrs: { type: "stop" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.score.scoreLock")
                                    ),
                                  },
                                },
                                [_vm._v("Scores lock")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0004") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "timetables",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "schedule" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.timetable.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("Timetable")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2560339099
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000401")
                    ? _c(
                        "a-menu-item",
                        { key: "teaching-assignment" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/teaching-assignment" } },
                            [
                              _c("a-icon", { attrs: { type: "solution" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.timetable.teachingAssignment"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Teaching assignment")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000402")
                    ? _c(
                        "a-menu-item",
                        { key: "timetable" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/timetable" } },
                            [
                              _c("a-icon", { attrs: { type: "table" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.timetable.timetable")
                                    ),
                                  },
                                },
                                [_vm._v("Timetable")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000405")
                    ? _c(
                        "a-menu-item",
                        { key: "timetable-teacher" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/timetable-teacher" } },
                            [
                              _c("a-icon", { attrs: { type: "table" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.timetable.timetableTeacher"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Timetable teacher")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000403")
                    ? _c(
                        "a-menu-item",
                        { key: "lecture-schedule" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/lecture-schedule" } },
                            [
                              _c("a-icon", { attrs: { type: "container" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.timetable.lectureSchedule"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Lecture schedule")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000404")
                    ? _c(
                        "a-menu-item",
                        { key: "teaching-schedule" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/teaching-schedule" } },
                            [
                              _c("a-icon", { attrs: { type: "carry-out" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.timetable.teachingSchedule"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Teaching schedule")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000404")
                    ? _c(
                        "a-menu-item",
                        { key: "additional-teaching-schedule" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/additional-teaching-schedule" } },
                            [
                              _c("a-icon", { attrs: { type: "carry-out" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.timetable.additionalTeachingSchedule"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Additional teaching schedule")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0005") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "student-and-teacher",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "contacts" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "global.menu.studentAndTeacher.main"
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("Student and teacher")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    278566957
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000501")
                    ? _c(
                        "a-menu-item",
                        { key: "student" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/student" } },
                            [
                              _c("a-icon", { attrs: { type: "user-add" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.studentAndTeacher.student"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Student")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000502")
                    ? _c(
                        "a-menu-item",
                        { key: "teacher" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/teacher" } },
                            [
                              _c("a-icon", { attrs: { type: "user-add" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.studentAndTeacher.teacher"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Teacher")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000503")
                    ? _c(
                        "a-menu-item",
                        { key: "feedback" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/feedback" } },
                            [
                              _c("a-icon", { attrs: { type: "mail" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.studentAndTeacher.feedback"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Feedback")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0006") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "kitchen",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "coffee" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.kitchen.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("Kitchen")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2174465330
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000601")
                    ? _c(
                        "a-menu-item",
                        { key: "price" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/price" } },
                            [
                              _c("a-icon", { attrs: { type: "dollar" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.kitchen.price")
                                    ),
                                  },
                                },
                                [_vm._v("Price")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000602")
                    ? _c(
                        "a-menu-item",
                        { key: "order-time" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/order-time" } },
                            [
                              _c("a-icon", { attrs: { type: "clock-circle" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.kitchen.orderTime")
                                    ),
                                  },
                                },
                                [_vm._v("Order time")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000603")
                    ? _c(
                        "a-menu-item",
                        { key: "menu" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/menu" } },
                            [
                              _c("a-icon", { attrs: { type: "menu" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.kitchen.menu")
                                    ),
                                  },
                                },
                                [_vm._v("Menu")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000604")
                    ? _c(
                        "a-menu-item",
                        { key: "order" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/order" } },
                            [
                              _c("a-icon", {
                                attrs: { type: "shopping-cart" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.kitchen.order")
                                    ),
                                  },
                                },
                                [_vm._v("Order")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000606")
                    ? _c(
                        "a-menu-item",
                        { key: "order-delete" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/order-delete" } },
                            [
                              _c("a-icon", {
                                attrs: { type: "shopping-cart" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.kitchen.orderDelete")
                                    ),
                                  },
                                },
                                [_vm._v("Order delete")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000605")
                    ? _c(
                        "a-menu-item",
                        { key: "statistics" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/statistics" } },
                            [
                              _c("a-icon", { attrs: { type: "pie-chart" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.kitchen.statistics")
                                    ),
                                  },
                                },
                                [_vm._v("Statistics")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0007") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "finance",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "dollar" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.finance.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("Finance")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    153352582
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000701")
                    ? _c(
                        "a-menu-item",
                        { key: "payment-category" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/payment-category" } },
                            [
                              _c("a-icon", { attrs: { type: "credit-card" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.finance.paymentCategory"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Payment category")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000702")
                    ? _c(
                        "a-menu-item",
                        { key: "invoice" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/invoice" } },
                            [
                              _c("a-icon", { attrs: { type: "file-done" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.finance.invoice")
                                    ),
                                  },
                                },
                                [_vm._v("Invoice")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0008") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "notifications",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "alert" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.notification.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("Notification")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    560725040
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000801")
                    ? _c(
                        "a-menu-item",
                        { key: "notification" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/notification" } },
                            [
                              _c("a-icon", { attrs: { type: "notification" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.notification.timekeepingNotification"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Timekeeping notification")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000802")
                    ? _c(
                        "a-menu-item",
                        { key: "school-notification" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/school-notification" } },
                            [
                              _c("a-icon", { attrs: { type: "notification" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.notification.schoolNotification"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("School notification")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000803")
                    ? _c(
                        "a-menu-item",
                        { key: "notification-control" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/notification-control" } },
                            [
                              _c("a-icon", { attrs: { type: "control" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.notification.notificationControl"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Notification control")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000804")
                    ? _c(
                        "a-menu-item",
                        { key: "attendance-time" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/attendance-time" } },
                            [
                              _c("a-icon", { attrs: { type: "clock-circle" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.notification.attendanceTime"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Attendance time")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0009") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "report",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "area-chart" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.report.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("Report")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    876604488
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000901")
                    ? _c(
                        "a-menu-item",
                        { key: "score-report-teacher" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/score-report-teacher" } },
                            [
                              _c("a-icon", { attrs: { type: "file-excel" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.report.scoreReportTeacher"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Score report teacher")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000902")
                    ? _c(
                        "a-menu-item",
                        { key: "reward-and-discipline-report" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/reward-and-discipline-report" } },
                            [
                              _c("a-icon", { attrs: { type: "file-excel" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.report.rewardAndDisciplineReport"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("rewardAndDisciplineReport")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000903")
                    ? _c(
                        "a-menu-item",
                        { key: "subjectScoreReport" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "" } },
                            [
                              _c("a-icon", { attrs: { type: "file-excel" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.report.subjectScoreReport"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("subjectScoreReport")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000904")
                    ? _c(
                        "a-menu-item",
                        { key: "parentFeedbackReport" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "" } },
                            [
                              _c("a-icon", { attrs: { type: "file-excel" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.report.parentFeedbackReport"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("parentFeedbackReport")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000905")
                    ? _c(
                        "a-menu-item",
                        { key: "teacherFeedbackReport" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "" } },
                            [
                              _c("a-icon", { attrs: { type: "file-excel" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.report.teacherFeedbackReport"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("teacherFeedbackReport")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000906")
                    ? _c(
                        "a-menu-item",
                        { key: "unfulfilled-teaching-schedule-report" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: "/unfulfilled-teaching-schedule-report",
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "file-excel" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.report.unfulfilledTeachingScheduleReport"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("unfulfilledTeachingScheduleReport")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("000907")
                    ? _c(
                        "a-menu-item",
                        { key: "timetable-all-course-report" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/timetable-all-course-report" } },
                            [
                              _c("a-icon", { attrs: { type: "file-excel" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.report.timetableAllCourse"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("timetableAllCourse")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAnyFunctional("0011") && _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  key: "website",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "ie" } }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-bold",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.website.main")
                                      ),
                                    },
                                  },
                                  [_vm._v("Website")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3724885938
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.hasAnyFunctional("001101")
                    ? _c(
                        "a-menu-item",
                        { key: "news-category" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/news-category" } },
                            [
                              _c("a-icon", {
                                attrs: { type: "unordered-list" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.website.newsCategory")
                                    ),
                                  },
                                },
                                [_vm._v("News category")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("001102")
                    ? _c(
                        "a-menu-item",
                        { key: "tag" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/tag" } },
                            [
                              _c("a-icon", { attrs: { type: "tags" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.website.tag")
                                    ),
                                  },
                                },
                                [_vm._v("Tag")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("001103")
                    ? _c(
                        "a-menu-item",
                        { key: "news" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/news" } },
                            [
                              _c("a-icon", { attrs: { type: "read" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.website.news")
                                    ),
                                  },
                                },
                                [_vm._v("News")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyFunctional("001104")
                    ? _c(
                        "a-menu-item",
                        { key: "home-page" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/home-page" } },
                            [
                              _c("a-icon", { attrs: { type: "read" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.website.homePage")
                                    ),
                                  },
                                },
                                [_vm._v("Home Page")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-layout",
    { style: { overflow: "auto", height: "100vh" }, attrs: { id: "app" } },
    [
      _c("jhi-navbar"),
      _vm._v(" "),
      _c(
        "a-layout",
        [
          _c("jhi-sidebar"),
          _vm._v(" "),
          _c(
            "a-layout",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "a-layout-content",
                [
                  _c("router-view"),
                  _vm._v(" "),
                  _c(
                    "b-modal",
                    {
                      attrs: { id: "login-page", "hide-footer": "", lazy: "" },
                    },
                    [
                      _c(
                        "span",
                        {
                          attrs: {
                            slot: "modal-title",
                            "data-cy": "loginTitle",
                            id: "login-title",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.$t("login.title")),
                          },
                          slot: "modal-title",
                        },
                        [_vm._v("Sign in")]
                      ),
                      _vm._v(" "),
                      _c("login-form"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("feedback-form"),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "loading-overlay" } },
        [
          _c(
            "a-spin",
            [
              _c("a-icon", {
                staticStyle: { "font-size": "24px" },
                attrs: { slot: "indicator", type: "loading", spin: "" },
                slot: "indicator",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }